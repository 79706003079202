import { SocialIcon } from "react-social-icons";
import { Link } from "react-router-dom";
import customIcon from "../../assets/icons8-whatsapp.svg";

function Footer({ asada }) {
  const { direccion, nombre, contactos } = asada;

  let correoPrincipal = undefined;
  let telefonoPrincipal = undefined;
  let facebook = undefined;
  let whatsapp = undefined;

  if (contactos && contactos.length > 0) {
    correoPrincipal = contactos.filter(
      (contacto) => contacto.tipo === "correo"
    )[0]; // Get the very first item
    telefonoPrincipal = contactos.filter(
      (contacto) => contacto.tipo === "fijo"
    )[0]; // Get the very first item
    facebook = contactos.filter((contacto) => contacto.tipo === "facebook")[0]; // Get the very first item
    whatsapp = contactos.filter((contacto) => contacto.tipo === "whatsapp")[0]; // Get the very first item
  }

  // const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className='py-16 footer bg-accent-focus'>
        <div className='flex flex-col lg:flex-row container mx-auto lg:px-20'>
          <div className='flex flex-col gap-3 items-center justify-center md:self-center place-self-center col-span-3 xl:col-span-1 mb-6 xl:mb-0 text-slate-100'>
            <h1 className='text-3xl lg:text-6xl  font-bold text-center'>
              {nombre}
            </h1>
            <blockquote className='text-2xl italic text-center'>
              "Cuidar del agua es cuidar de todos nosotros."
            </blockquote>
            {/*  <p>{currentYear}</p> */}
          </div>

          <div className='flex flex-col gap-1 justify-center place-items-center w-full'>
            <p className='footer-title text-xl capitalize text-slate-50'>
              Navegación
            </p>
            <Link to='/' className='link link-hover text-lg text-slate-100'>
              Inicio
            </Link>
            <Link
              to='/nosotros'
              className='link link-hover text-lg text-slate-100'
            >
              Nosotros
            </Link>
            <Link
              to='/servicios'
              className='link link-hover text-lg text-slate-100'
            >
              Servicios
            </Link>
            <Link
              to='/proyectos'
              className='link link-hover text-lg text-slate-100'
            >
              Proyectos
            </Link>
            <Link
              to='/documentacion'
              className='link link-hover text-lg text-slate-100'
            >
              Documentación
            </Link>
            <Link
              to='/contacto'
              className='link link-hover text-lg text-slate-100'
            >
              Contacto
            </Link>
          </div>

          <div className='flex flex-col gap-1 justify-center place-items-center w-full mt-12 lg:mt-0'>
            <p className='footer-title capitalize text-bold text-slate-50 text-xl'>
              De interés
            </p>
            <Link
              to='/documentacion#transparencia'
              className='link link-hover text-lg text-slate-100'
            >
              Transparencia
            </Link>
            <Link
              to='/publicaciones'
              className='link link-hover text-lg text-slate-100'
            >
              Publicaciones
            </Link>
            <Link
              to='/galeria'
              className='link link-hover text-lg text-slate-100'
            >
              Galería
            </Link>
            <Link
              to='/contacto'
              className='link link-hover text-lg text-slate-100 text-center'
            >
              Buzón de sugerencias
            </Link>{" "}
            <Link
              to='/servicios'
              className='link link-hover text-lg text-slate-100 text-center'
            >
              Consulta de recibos
            </Link>
            <Link
              to='/contacto'
              className='link link-hover text-lg text-slate-100 text-center'
            >
              Trabaja con nosotros
            </Link>
          </div>

          <div className='flex flex-col gap-1 justify-center place-items-center w-full mt-12 lg:mt-0'>
            <p className='footer-title capitalize text-slate-50 text-xl '>
              Oficinas Centrales
            </p>
            <div className='flex text-base-300 place-items-center'>
              <span className='flex py-1 cursor-pointer mr-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-8 w-8'
                  fill='#FFFFFF'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  strokeWidth='2'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15 11a3 3 0 11-6 0 3 3 0 016 0z'
                  />
                </svg>
              </span>
              <address className='text-lg max-w-xs text-center text-slate-100'>
                {direccion && direccion}
              </address>
            </div>
            <div className='flex text-base-300 place-items-center'>
              <span className='flex py-1 cursor-pointer mr-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-8 w-8 '
                  viewBox='0 0 20 20'
                  fill='#FFFFFF'
                  stroke='#000000'
                >
                  <path d='M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z' />
                </svg>
              </span>
              <p className='text-lg text-slate-100'>
                (+506){" "}
                {telefonoPrincipal !== undefined
                  ? telefonoPrincipal.info
                  : "sin teléfono"}
              </p>
            </div>
            <div className='flex text-base-300 place-items-center'>
              <span className='flex py-1 cursor-pointer text-base-100 items-center mr-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-8 w-8 '
                  viewBox='0 0 20 20'
                  fill='#FFFFFF'
                  stroke='#000000'
                >
                  <path d='M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z' />
                  <path d='M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z' />
                </svg>
              </span>
              <p className='text-lg text-slate-100'>
                {correoPrincipal !== undefined
                  ? correoPrincipal.info
                  : "sin correo"}
              </p>
            </div>
            <div className='mt-12 lg:mt-6'>
              <p className=' font-bold text-xl text-slate-100'>
                Encuéntranos en:{" "}
              </p>
              <div className='flex gap-2 mt-2 justify-center'>
                <SocialIcon
                  url={
                    facebook !== undefined
                      ? facebook.info
                      : "https://www.facebook.com/"
                  }
                  style={{ width: "42px", height: "42px" }}
                  className='transition hover:scale-125  duration-300  cursor-pointer '
                  target='_blank'
                  fgColor='#fff'
                />

                <SocialIcon
                  url={`mailto:${
                    correoPrincipal !== undefined
                      ? correoPrincipal.info
                      : "sincorreo@sincorreo.com"
                  }`}
                  style={{ width: "42px", height: "42px" }}
                  className='transition hover:scale-125 duration-300  cursor-pointer'
                  fgColor='#fff'
                />
                {/* WHATSAPP */}
                <a
                  href={`https://wa.me/506${
                    whatsapp !== undefined ? whatsapp.info : ""
                  } `}
                  target='_blank'
                  style={{ width: "42px", height: "42px" }}
                >
                  <img
                    src={customIcon}
                    alt='WhatsApp icon'
                    className='transition hover:scale-125 duration-300 cursor-pointer'
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
