import { createContext, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import LoginContext from "../login/LoginContext";
import { toast } from "react-toastify";
import axios from "axios";

const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const { user } = useContext(LoginContext);

  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState({});
  const [proyectos, setProyectos] = useState([]);
  const [proyecto, setProyecto] = useState({});

  const navigate = useNavigate();

  // Get all projects
  const getProjects = async () => {
    setIsLoading(true);
    const response = await axios.get("/api/projects", {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data.projects;
    setProjects(data);
    setIsLoading(false);
  };

  // Get all projects
  const getAllProyectos = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/proyectos?aid=${asadaId}`
    );
    const data = await response.data;
    const proyectos = data.proyectos;
    setProyectos(proyectos);
    setIsLoading(false);
  };

  // Get all projects
  const getAllProyectosPrivate = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/proyectos/private?aid=${asadaId}`
    );
    const data = await response.data;
    const proyectos = data.proyectos;
    setProyectos(proyectos);
    setIsLoading(false);
  };

  // Get single proyecto
  const getSingleProyecto = async (proyectoId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/proyectos/${proyectoId}`
    );
    const data = await response.data;
    const proyecto = data.proyecto;
    setProyecto(proyecto);
    setIsLoading(false);
  };

  // Get project by Id
  const getProject = async (id) => {
    setIsLoading(true);
    const response = await axios.get(`/api/projects/${id}`);
    const data = await response.data;
    setProject(data);
    setIsLoading(false);
  };

  // Get project photo by Id
  const getPhoto = async (id) => {
    setIsLoading(true);
    const response = await axios.get(`/api/projects/photo/${id}`);
    const data = await response.data;
    setProject(data);
    setIsLoading(false);
  };

  // Create a proyecto
  const createProyecto = async (newProyecto, asadaId) => {
    try {
      setIsLoading(true);
      let response;
      if (asadaId) {
        response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/proyectos?aid=${asadaId}`,
          newProyecto,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/proyectos?aid=${process.env.REACT_APP_ASADA_ID}`,
          newProyecto,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      const data = await response.data;
      const proyecto = data.proyecto;

      setProyectos(...proyectos, proyecto);
      setIsLoading(false);

      toast.success(`Nuevo proyecto agregado a la ASADA: ${proyecto.nombre}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (asadaId) await getAllProyectosPrivate(asadaId);
      else await getAllProyectos(process.env.REACT_APP_ASADA_ID);
    } catch (error) {
      console.log(error);
      toast.error(
        `Ha ocurrido un error al agregar el proyecto. Error: ${error}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setIsLoading(false);
      throw new Error(error);
    }
  };

  // Add project
  const addProject = async (newProject) => {
    try {
      setIsLoading(true);
      const response = await axios.post("/api/projects", newProject, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      });

      const data = await response.data;

      await getProjects();
      setProjects(data);
      setIsLoading(false);
      toast.success(`¡Nuevo proyecto agregado a la ASADA!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      toast.error(
        `Ha ocurrido un error al agregar el formulario. Error: ${error}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      throw new Error(error);
    }
  };

  // Delete photo
  const deleteProjectPhoto = async (fotoId, proyectoId) => {
    try {
      setIsLoading(true);

      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/v1/fotos/${fotoId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      await getSingleProyecto(proyectoId);
      setIsLoading(false);
      toast.info(`Foto borrada del proyecto`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      throw new Error(
        `Error al actualizar informacion del proyecto: ${project.name}`
      );
    }
  };

  // Update proyecto admin
  const updateProyectoAdmin = async (newProyecto, proyectoId, restore) => {
    try {
      setIsLoading(true);

      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/proyectos/${proyectoId}${
          restore ? "?restore=true" : ""
        }`,
        newProyecto,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = await response.data;
      const proyecto = data.proyecto;

      await getSingleProyecto(proyectoId);
      setIsLoading(false);
      toast.info(`Proyecto actualizado.`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      toast.error(
        `Ha ocurrido un error al editar el proyecto. Error: ${error}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setIsLoading(false);
      throw new Error(`Error al actualizar proyecto: ${newProyecto}`);
    }
  };

  // Update a proyecto
  const updateProyecto = async (proyecto, id) => {
    try {
      setIsLoading(true);
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/proyectos/${id}`,
        proyecto,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = await response.data;
      const proyect = data.proyecto;
      setProyecto(proyect);
      setIsLoading(false);
      toast.info(
        `Se ha actualizado la información del proyecto: ${proyecto.nombre}`,
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      throw new Error(
        `Error al actualizar informacion de proyecto: ${proyecto.nombre}`
      );
    }
  };

  // Delete a proyecto
  const deleteProyecto = async (proyectoId, force) => {
    try {
      setIsLoading(true);
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/v1/proyectos/${proyectoId}${
          force ? "?force=true" : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      await getAllProyectos(process.env.REACT_APP_ASADA_ID);

      setIsLoading(false);
      toast.info(`Se ha borrado el registro del proyecto`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate(-1);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      throw new Error(`Error al borrar proyecto: ${proyecto.nombre}`);
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        getProjects,
        getProject,
        isLoading,
        setIsLoading,
        setProject,
        project,
        projects,
        addProject,
        deleteProjectPhoto,
        getPhoto,
        getAllProyectos,
        proyectos,
        proyecto,
        deleteProyecto,
        setProyecto,
        setProyectos,
        createProyecto,
        updateProyecto,
        getSingleProyecto,
        getAllProyectosPrivate,
        updateProyectoAdmin,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectContext;
