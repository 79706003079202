import { createContext, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import LoginContext from "../login/LoginContext";
import { toast } from "react-toastify";
import axios from "axios";

const QuiebraGradientesContext = createContext();

export const QuiebraGradientesProvider = ({ children }) => {
  const { user } = useContext(LoginContext);

  const [isLoading, setIsLoading] = useState(true);
  const [gradientes, setGradientes] = useState([]);
  const [gradiente, setGradiente] = useState({});

  const navigate = useNavigate();

  // Get all quiebragradientes
  const getGradientes = async () => {
    setIsLoading(true);
    const response = await axios.get("/api/quiebraGradientes", {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data.gradientes;
    setGradientes(data);
    setIsLoading(false);
  };

  // Get all gradientes
  const getAllGradientes = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/gradientes?aid=${asadaId}`
    );
    const data = await response.data;
    const gradientes = data.gradientes;
    setGradientes(gradientes);
    setIsLoading(false);
  };

  // Get all gradientes private
  const getAllGradientesPrivate = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/gradientes/private?aid=${asadaId}`
    );
    const data = await response.data;
    const gradientes = data.gradientes;
    setGradientes(gradientes);
    setIsLoading(false);
  };

  // Get single gradiente
  const getSingleGradiente = async (gradienteId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/gradientes/${gradienteId}`
    );
    const data = await response.data;
    const gradiente = data.gradiente;
    setGradiente(gradiente);
    setIsLoading(false);
  };

  // Create a gradiente
  const createGradiente = async (newGradiente, asadaId) => {
    try {
      setIsLoading(true);
      let response;
      if (asadaId) {
        response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/gradientes?aid=${asadaId}`,
          newGradiente,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/gradientes?aid=${process.env.REACT_APP_ASADA_ID}`,
          newGradiente,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      const data = await response.data;
      const gradiente = data.gradiente;

      setGradientes(...gradientes, gradiente);

      setIsLoading(false);

      toast.success(`Nuevo quiebragradiente agregado.`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (asadaId) await getAllGradientesPrivate(asadaId);
      else await getAllGradientes(process.env.REACT_APP_ASADA_ID);
    } catch (error) {
      console.log(error);
      toast.error(
        `Ha ocurrido un error al agregar el quiebragradiente. Error: ${error}`,
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setIsLoading(false);
      throw new Error(error);
    }
  };

  // Get gradiente by Id
  const getGradiente = async (id) => {
    setIsLoading(true);
    const response = await axios.get(`/api/quiebraGradientes/${id}`);
    const data = await response.data;
    setGradiente(data);
    setIsLoading(false);
  };

  // Add quiebra gradiente
  const addGradiente = async (newGradiente) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "/api/quiebraGradientes",
        newGradiente,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const data = await response.data;

      await getGradientes();
      setGradiente(data);
      setIsLoading(false);
      toast.success(`¡Nuevo quiebragradientes agregado a la ASADA!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      toast.error(
        `Ha ocurrido un error al agregar el quiebragradiente. Error: ${error}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      throw new Error(error);
    }
  };

  // Update gradiente admin
  const updateGradienteAdmin = async (newGradiente, gradienteId, restore) => {
    try {
      setIsLoading(true);

      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/gradientes/${gradienteId}${
          restore ? "?restore=true" : ""
        }`,
        newGradiente,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = await response.data;
      const gradiente = data.gradiente;

      await getSingleGradiente(gradienteId);
      setIsLoading(false);
      toast.info(`Quiebragradientes actualizado`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      toast.error(
        `Ha ocurrido un error al editar el quiebragradientes. Error: ${error}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setIsLoading(false);
      throw new Error(`Error al actualizar quiebragradientes: ${newGradiente}`);
    }
  };

  // Update gradiente
  const updateGradiente = async (gradiente, gradienteId) => {
    try {
      setIsLoading(true);
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/gradientes/${gradienteId}`,
        gradiente,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = await response.data;
      const gradient = data.gradiente;
      setGradiente(gradient);
      setIsLoading(false);
      toast.info(`Se ha actualizado la información del quiebragradientes`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      throw new Error(
        `Error al actualizar informacion de quiebragradientes: ${gradiente.name}`
      );
    }
  };

  // Delete photo
  const deleteGradientePhoto = async (fotoId, gradienteId) => {
    try {
      setIsLoading(true);

      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/v1/fotos/${fotoId}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      await getSingleGradiente(gradienteId);
      setIsLoading(false);
      toast.info(`Foto borrada del quiebragradientes`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      throw new Error(
        `Error al actualizar informacion del quiebragradientes: ${gradiente.name}`
      );
    }
  };

  // Delete quiebragradientes
  const deleteGradiente = async (gradienteId, force) => {
    try {
      setIsLoading(true);

      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/v1/gradientes/${gradienteId}${
          force ? "?force=true" : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      await getAllGradientes(process.env.REACT_APP_ASADA_ID);

      setIsLoading(false);
      toast.info(
        `Se ha borrado el registro del quiebragradientes y las fotos asociadas`,
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      navigate(-1);
    } catch (error) {
      console.log(error);
      throw new Error(`Error al borrar quiebragradientes: ${gradiente.name}`);
    }
  };

  return (
    <QuiebraGradientesContext.Provider
      value={{
        getGradientes,
        isLoading,
        setIsLoading,
        gradiente,
        gradientes,
        addGradiente,
        updateGradiente,
        getGradiente,
        setGradiente,
        deleteGradientePhoto,
        deleteGradiente,
        getAllGradientes,
        createGradiente,
        getSingleGradiente,
        getAllGradientesPrivate,
        updateGradienteAdmin,
      }}
    >
      {children}
    </QuiebraGradientesContext.Provider>
  );
};

export default QuiebraGradientesContext;
