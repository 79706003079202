import { createContext, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LoginContext from "../login/LoginContext";

import axios from "axios";

import { toast } from "react-toastify";

const PostContext = createContext();

export const PostProvider = ({ children }) => {
  const { user } = useContext(LoginContext);
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [publicaciones, setPublicaciones] = useState([]);
  const [publicacion, setPublicacion] = useState({});
  const [post, setPost] = useState({});

  // Get all posts
  const getPosts = async () => {
    setIsLoading(true);
    const response = await axios.get("/api/posts", {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    setPosts(data.posts);
    setIsLoading(false);
    return;
  };

  // Get all posts
  const getAllPublicaciones = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/publicaciones?aid=${asadaId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.data;
    const publicaciones = data.publicaciones;
    setPublicaciones(publicaciones);
    setIsLoading(false);
  };

  // Get all publicaciones private
  const getAllPublicacionesPrivate = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/publicaciones/private?aid=${asadaId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.data;
    const publicaciones = data.publicaciones;
    setPublicaciones(publicaciones);
    setIsLoading(false);
  };

  // Get single publicacion
  const getSinglePublicacion = async (publicacionId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/publicaciones/${publicacionId}`
    );
    const data = await response.data;
    const publicacion = data.publicacion;
    setPublicacion(publicacion);
    setIsLoading(false);
  };

  // Get post by Id
  const getPost = async (id) => {
    setIsLoading(true);
    const response = await axios.get(`/api/posts/${id}`);
    const data = await response.data;
    setPost(data);
    setIsLoading(false);
  };

  // Create a publicacion
  const createPublicacion = async (newPublicacion, asadaId) => {
    try {
      setIsLoading(true);
      let response;
      if (asadaId) {
        response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/publicaciones?aid=${asadaId}`,
          newPublicacion,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/publicaciones?aid=${process.env.REACT_APP_ASADA_ID}`,
          newPublicacion,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      const data = await response.data;
      const publicacion = data.publicacion;

      setPublicaciones(...publicaciones, publicacion);
      setIsLoading(false);

      toast.success(`Nuevo publicación agregada: ${publicacion.titulo}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (asadaId) await getAllPublicacionesPrivate(asadaId);
      else await getAllPublicaciones(process.env.REACT_APP_ASADA_ID);
    } catch (error) {
      console.log(error);
      toast.error(
        `Ha ocurrido un error al agregar la publicación. Error: ${error}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setIsLoading(false);
      throw new Error(error);
    }
  };

  // Update publicacion admin
  const updatePublicacionAdmin = async (
    newPublicacion,
    publicacionId,
    restore
  ) => {
    try {
      setIsLoading(true);

      const response = await axios.patch(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/v1/publicaciones/${publicacionId}${
          restore ? "?restore=true" : ""
        }`,
        newPublicacion,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = await response.data;
      const publicacion = data.publicacion;

      await getSinglePublicacion(publicacionId);
      setIsLoading(false);
      toast.info(`Publicación actualizada.`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      toast.error(
        `Ha ocurrido un error al editar la publicación. Error: ${error}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setIsLoading(false);
      throw new Error(`Error al actualizar publicación: ${newPublicacion}`);
    }
  };

  // Update a publicacion
  const updatePublicacion = async (publicacion, id) => {
    try {
      setIsLoading(true);
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/publicaciones/${id}`,
        publicacion,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = await response.data;
      const post = data.publicacion;
      setPublicacion(post);
      setIsLoading(false);
      toast.info(
        `Se ha actualizado la información del proyecto: ${publicacion.titulo}`,
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      throw new Error(
        `Error al actualizar informacion de proyecto: ${publicacion.titulo}`
      );
    }
  };

  const deletePublicacion = async (publicacionId, force) => {
    try {
      setIsLoading(true);
      await axios.delete(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/v1/publicaciones/${publicacionId}${force ? "?force=true" : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      await getAllPublicaciones(process.env.REACT_APP_ASADA_ID);

      setIsLoading(false);
      toast.info(`Se ha borrado el registro de la publicación`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate(-1);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      throw new Error(`Error al borrar publicación: ${publicacion.titulo}`);
    }
  };

  // Delete photo
  const deletePostPhoto = async (fotoId, publicacionId) => {
    try {
      setIsLoading(true);

      await axios.delete(`/api/v1/fotos/${fotoId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      await getSinglePublicacion(publicacionId);
      setIsLoading(false);
      toast.info(`Foto borrada de la publicación`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      throw new Error(
        `Error al actualizar informacion de la publicación: ${publicacion.titulo}`
      );
    }
  };

  // Delete post
  const deletePost = async () => {
    try {
      setIsLoading(true);
      const { id } = params;
      await axios.delete(`/api/posts/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      await getPosts();

      setIsLoading(false);
      toast.info(
        `Se ha borrado el registro de la publicación y las fotos asociadas`,
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      navigate(-1);
    } catch (error) {
      console.log(error);
      throw new Error(`Error al borrar formulario: ${post.title}`);
    }
  };

  return (
    <PostContext.Provider
      value={{
        getPosts,
        getPost,
        setPost,
        isLoading,
        setIsLoading,
        posts,
        post,
        updatePublicacion,
        deletePostPhoto,
        deletePost,
        getAllPublicaciones,
        publicaciones,
        publicacion,
        setPublicacion,
        setPublicaciones,
        getSinglePublicacion,
        createPublicacion,
        getAllPublicacionesPrivate,
        updatePublicacionAdmin,
        deletePublicacion,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};

export default PostContext;
