import { createContext, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import LoginContext from "../login/LoginContext";
import FileDownload from "js-file-download";

import { toast } from "react-toastify";

const ReglamentosContext = createContext();

export const ReglamentosProvider = ({ children }) => {
  const { user } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(true);

  const [reglamentos, setReglamentos] = useState([]);
  const [reglamento, setReglamento] = useState({});

  const params = useParams();
  const navigate = useNavigate();

  // Get all reglamentos
  const getReglamentos = async () => {
    setIsLoading(true);
    const response = await fetch("/api/reglamentos", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    setReglamentos(data);
    setIsLoading(false);
  };

  // Get all reglamentos
  const getAllReglamentos = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/reglamentos?aid=${asadaId}`
    );
    const data = await response.data;
    const reglamentos = data.reglamentos;
    setReglamentos(reglamentos);
    setIsLoading(false);
  };

  // Get all reglamentos
  const getAllReglamentosPrivate = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/reglamentos/private?aid=${asadaId}`
    );
    const data = await response.data;
    const reglamentos = data.reglamentos;
    setReglamentos(reglamentos);
    setIsLoading(false);
  };

  // Get single reglamento
  const getSingleReglamento = async (reglamentoId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/reglamentos/${reglamentoId}`
    );
    const data = await response.data;
    const reglamento = data.reglamento;
    setReglamento(reglamento);
    setIsLoading(false);
  };

  // Get reglamento by Id
  const getReglamento = async (id) => {
    setIsLoading(true);
    const response = await axios.get(`/api/reglamentos/${id}`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const data = await response.data;
    setReglamento(data);

    setIsLoading(false);
    return data;
  };

  // Create reglamento
  const createReglamento = async (newReglamento, asadaId) => {
    try {
      setIsLoading(true);
      let response;
      if (asadaId) {
        response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/reglamentos?aid=${asadaId}`,
          newReglamento,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/reglamentos?aid=${process.env.REACT_APP_ASADA_ID}`,
          newReglamento,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      const data = await response.data;
      const reglamento = data.reglamento;
      setReglamentos(...reglamentos, reglamento);
      setIsLoading(false);
      toast.success(
        `Se ha agregado un nuevo reglamento a la ASADA. Nombre: ${reglamento.nombre}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      if (asadaId) await getAllReglamentosPrivate(asadaId);
    } catch (error) {
      console.log(error);
      toast.error(
        `Ha ocurrido un error al agregar el reglamento. Error: ${error}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setIsLoading(false);
      throw new Error(error);
    }
  };

  // Download document as attachement
  const downloadReglamentoDocument = async () => {
    setIsLoading(true);
    const { id } = params,
      download = await axios.get(`/api/reglamentos/${id}/download`, {
        responseType: "blob",
      }),
      response = await axios.get(`/api/reglamentos/${id}`),
      file = await response.data.rdoc,
      fileName = file.split("-")[0],
      fileExt = file.split(".")[1];

    FileDownload(download.data, `${fileName}.${fileExt}`);
    setIsLoading(false);
  };

  // Download reglamento
  const downloadReglamentoDocumentClient = async (id) => {
    setIsLoading(true);

    const download = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/reglamentos/${id}/download`,
      {
        responseType: "blob",
      }
    );
    const response = await axios.get(`/api/v1/reglamentos/${id}`);
    const data = await response.data;
    const { file } = data.reglamento;

    FileDownload(download.data, `${file.split("/")[5]}`);
    setIsLoading(false);
  };

  // Display/show reglamento in different tab
  const displayReglamento = async (reglamentoId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/reglamentos/${reglamentoId}/display`,
      {
        responseType: "blob",
      }
    );
    const data = await response.data;

    const file = new Blob([data], {
      type: "application/pdf",
    });

    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  // Display/show reglamento in client view in different tab
  const displayReglamentoClient = async (id) => {
    const response = await axios.get(`/api/reglamentos/${id}/display`, {
      method: "GET",
      responseType: "blob",
    });

    const file = new Blob([response.data], {
      type: "application/pdf",
    });

    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  // Add reglamento
  const addReglamento = async (newReglamento) => {
    try {
      setIsLoading(true);
      const response = await axios.post("/api/reglamentos", newReglamento, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      });

      const data = await response.data;

      setReglamentos(...reglamentos, data);
      setIsLoading(false);
      toast.success(
        `Se ha agregado un nuevo reglamento a la ASADA. Nombre: ${data.reglamento.rname}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } catch (error) {
      console.log(error);
      toast.error(
        `Ha ocurrido un error al agregar el reglamento. Error: ${error}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      throw new Error(error);
    }
  };

  // Update reglamento admin
  const updateReglamentoAdmin = async (
    newReglamento,
    reglamentoId,
    restore
  ) => {
    try {
      setIsLoading(true);

      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reglamentos/${reglamentoId}${
          restore ? "?restore=true" : ""
        }`,
        newReglamento,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = await response.data;
      const reglamento = data.reglamento;

      // await getInformes();
      await getSingleReglamento(reglamentoId);
      setIsLoading(false);
      toast.info(`Reglamento actualizado: "${reglamento.nombre}"`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      toast.error(
        `Ha ocurrido un error al editar el reglamento. Error: ${error}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setIsLoading(false);
      throw new Error(`Error al actualizar informe: ${newReglamento}`);
    }
  };

  // Update reglamento
  const updateReglamento = async (newReglamento, reglamentoId) => {
    try {
      setIsLoading(true);
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reglamentos/${reglamentoId}`,
        newReglamento,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = await response.data;
      const reglamento = await data.reglamento;

      // await getReglamentos();
      await getSingleReglamento(reglamentoId);
      setIsLoading(false);
      toast.info(`Reglamento actualizado: "${reglamento.nombre}"`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      toast.error(`Ha ocurrido un error al editar el reglamento: ${error}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsLoading(false);
      throw new Error(`Error al actualizar formulario: ${newReglamento}`);
    }
  };

  const deleteReglamento = async (reglamentoId, force) => {
    try {
      setIsLoading(true);
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reglamentos/${reglamentoId}${
          force ? "?force=true" : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      await getAllReglamentos(process.env.REACT_APP_ASADA_ID);

      setIsLoading(false);
      toast.info(`Se ha borrado el registro del reglamento`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate(-1);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.info(`Ha ocurrido un error al borrar el reglamento: ${error}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(`Error al borrar reglamento: ${reglamento.nombre}`);
    }
  };

  return (
    <ReglamentosContext.Provider
      value={{
        getReglamentos,
        getReglamento,
        addReglamento,
        updateReglamento,
        downloadReglamentoDocument,
        downloadReglamentoDocumentClient,
        displayReglamento,
        reglamento,
        reglamentos,
        isLoading,
        setIsLoading,
        setReglamento,
        displayReglamentoClient,
        deleteReglamento,
        getAllReglamentos,
        createReglamento,
        getSingleReglamento,
        getAllReglamentosPrivate,
        updateReglamentoAdmin,
      }}
    >
      {children}
    </ReglamentosContext.Provider>
  );
};

export default ReglamentosContext;
