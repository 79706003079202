import { createContext, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import LoginContext from "../login/LoginContext";
import FileDownload from "js-file-download";

import { toast } from "react-toastify";

const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const { user } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(true);
  const [forms, setForms] = useState([]);
  const [formularios, setFormularios] = useState([]);
  const [formulario, setFormulario] = useState({});
  const [form, setForm] = useState({});

  const params = useParams();
  const navigate = useNavigate();
  // Get all forms
  const getForms = async () => {
    setIsLoading(true);
    const response = await fetch("/api/forms", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    setForms(data);
    setIsLoading(false);
  };

  // Get all formularios
  const getFormularios = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/formularios?aid=${asadaId}`
    );
    const data = await response.data;
    setForms(data.formularios);
    setIsLoading(false);
  };

  // Get all formularios
  const getAllFormularios = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/formularios?aid=${asadaId}`
    );
    const data = await response.data;
    const formularios = data.formularios;
    setFormularios(formularios);
    setIsLoading(false);
  };

  // Get all formularios private
  const getAllFormulariosPrivate = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/formularios/private?aid=${asadaId}`
    );
    const data = await response.data;
    const formularios = data.formularios;
    setFormularios(formularios);
    setIsLoading(false);
  };

  // Get form by Id
  const getForm = async (id) => {
    setIsLoading(true);
    const response = await axios.get(`/api/forms/${id}`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const data = await response.data;
    setForm(data);

    setIsLoading(false);
    return data;
  };

  // Get single formulario
  const getSingleFormulario = async (formularioId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/formularios/${formularioId}`
    );
    const data = await response.data;
    const formulario = data.formulario;
    setFormulario(formulario);
    setIsLoading(false);
  };

  // Download document as attachement
  const downloadFormDocument = async () => {
    setIsLoading(true);
    const { id } = params,
      download = await axios.get(`/api/forms/${id}/download`, {
        responseType: "blob",
      }),
      response = await axios.get(`/api/forms/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }),
      file = await response.data.fdoc,
      fileName = file.split("-")[0],
      fileExt = file.split(".")[1];

    FileDownload(download.data, `${fileName}.${fileExt}`);
    setIsLoading(false);
  };

  // Download form
  const downloadFormDocumentClient = async (id) => {
    setIsLoading(true);

    const download = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/formularios/${id}/download`,
      {
        responseType: "blob",
      }
    );
    const response = await axios.get(`/api/v1/formularios/${id}`);
    const data = await response.data;
    const { file } = data.formulario;

    FileDownload(download.data, `${file.split("/")[5]}`);
    setIsLoading(false);
  };

  // Display/show form in different tab
  const displayForm = async (id) => {
    const response = await axios.get(`/api/forms/${id}/display`, {
      method: "GET",
      responseType: "blob",
    });

    const file = new Blob([response.data], {
      type: "application/pdf",
    });

    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  // Display/show reglamento in client view in different tab
  const displayFormClient = async (formularioId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/formularios/${formularioId}/display`,
      {
        responseType: "blob",
      }
    );
    const data = await response.data;

    const file = new Blob([data], {
      type: "application/pdf",
    });

    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  // Create formulario
  const createFormulario = async (newForm, asadaId) => {
    try {
      setIsLoading(true);
      let response;
      if (asadaId) {
        response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/formularios?aid=${asadaId}`,
          newForm,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/formularios?aid=${process.env.REACT_APP_ASADA_ID}`,
          newForm,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      const data = await response.data;
      const formulario = data.formulario;

      setForms(...formularios, formulario);
      setIsLoading(false);
      toast.success(
        `Se ha agregado un nuevo formulario a la ASADA. Nombre: ${formulario.nombre}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      if (asadaId) await getAllFormulariosPrivate(asadaId);
    } catch (error) {
      console.log(error);
      toast.error(
        `Ha ocurrido un error al agregar el formulario. Error: ${error}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setIsLoading(false);

      throw new Error(error);
    }
  };

  // Update form
  const updateForm = async (newForm, formularioId) => {
    try {
      setIsLoading(true);
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/formularios/${formularioId}`,
        newForm,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = await response.data;
      const formulario = await data.formulario;

      // await getForms();
      await getSingleFormulario(formularioId);
      setIsLoading(false);
      toast.info(`Formulario actualizado: "${formulario.nombre}"`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error(
        `Ha ocurrido un error al editar el formulario. Error: ${error}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setIsLoading(false);
      throw new Error(`Error al actualizar formulario: ${newForm}`);
    }
  };

  // Update formulario
  const updateFormularioAdmin = async (
    newFormulario,
    formularioId,
    restore
  ) => {
    try {
      setIsLoading(true);

      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/formularios/${formularioId}${
          restore ? "?restore=true" : ""
        }`,
        newFormulario,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = await response.data;
      const formulario = data.formulario;

      // await getInformes();
      await getSingleFormulario(formularioId);
      setIsLoading(false);
      toast.info(`Informe actualizado: "${formulario.nombre}"`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      toast.error(
        `Ha ocurrido un error al editar el informe. Error: ${error}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setIsLoading(false);
      throw new Error(`Error al actualizar informe: ${newFormulario}`);
    }
  };

  // Delete form
  const deleteFormulario = async (formularioId, force) => {
    try {
      setIsLoading(true);
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/v1/formularios/${formularioId}${
          force ? "?force=true" : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      await getAllFormularios(process.env.REACT_APP_ASADA_ID);
      setIsLoading(false);

      toast.info(`Se ha borrado el registro del formulario`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate(-1);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      throw new Error(`Error al borrar formulario: ${form.fname}`);
    }
  };

  return (
    <FormContext.Provider
      value={{
        getForms,
        getForm,
        createFormulario,
        isLoading,
        setIsLoading,
        forms,
        form,
        setForm,
        downloadFormDocument,
        updateForm,
        downloadFormDocumentClient,
        displayForm,
        displayFormClient,
        deleteFormulario,
        getFormularios,
        formularios,
        formulario,
        setFormulario,
        setFormularios,
        getAllFormularios,
        getSingleFormulario,
        getAllFormulariosPrivate,
        updateFormularioAdmin,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormContext;
