import { createContext, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import LoginContext from "../login/LoginContext";
import FileDownload from "js-file-download";

import { toast } from "react-toastify";

const TransparenciaContext = createContext();

export const TransparenciaProvider = ({ children }) => {
  const { user } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(true);

  const [documentos, setDocumentos] = useState([]);
  const [documento, setDocumento] = useState({});
  const [transparencias, setTransparencias] = useState([]);
  const [transparencia, setTransparencia] = useState({});

  const params = useParams();
  const navigate = useNavigate();

  // Get all documentos
  const getDocumentos = async () => {
    setIsLoading(true);
    const response = await fetch("/api/transparencias", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    setDocumentos(data);
    setIsLoading(false);
  };

  // Get all transparencias
  const getTransparencias = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias?aid=${asadaId}`
    );
    const data = await response.data;
    const transparencias = data.transparencias;
    setDocumentos(transparencias);
    setIsLoading(false);
  };

  // Get all transparencias
  const getAllTransparencias = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias?aid=${asadaId}`
    );
    const data = await response.data;
    const transparencias = data.transparencias;
    setTransparencias(transparencias);
    setIsLoading(false);
  };

  // Get all transparencias private
  const getAllTransparenciasPrivate = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias/private?aid=${asadaId}`
    );
    const data = await response.data;
    const transparencias = data.transparencias;
    setTransparencias(transparencias);
    setIsLoading(false);
  };

  // Get documento by Id
  const getDocumento = async (id) => {
    setIsLoading(true);
    const response = await axios.get(`/api/transparencias/${id}`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const data = await response.data;
    setDocumento(data);

    setIsLoading(false);
    return data;
  };

  // Get single transparencia document
  const getSingleTransparencia = async (transparenciaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias/${transparenciaId}`
    );
    const data = await response.data;
    const transparencia = data.transparencia;
    setTransparencia(transparencia);
    setIsLoading(false);
  };

  // Download document as attachement
  const downloadTransparenciaDocument = async () => {
    setIsLoading(true);
    const { id } = params,
      download = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias/${id}/download`,
        {
          responseType: "blob",
        }
      ),
      response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias/${id}`
      ),
      file = await response.data.ddoc,
      fileName = file.split("-")[0],
      fileExt = file.split(".")[1];

    FileDownload(download.data, `${fileName}.${fileExt}`);
    setIsLoading(false);
  };

  // Download documento
  const downloadTransparenciaDocumentClient = async (id) => {
    setIsLoading(true);
    const download = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias/${id}/download`,
      {
        responseType: "blob",
      }
    );
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias/${id}`
    );
    const data = await response.data;
    const { file } = data.transparencia;

    FileDownload(download.data, `${file.split("/")[5]}`);
    setIsLoading(false);
  };

  // Display/show transparencia document in different tab
  const displayDocumento = async (transparenciaId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias/${transparenciaId}/display`,
      {
        responseType: "blob",
      }
    );
    const data = await response.data;

    const file = new Blob([data], {
      type: "application/pdf",
    });

    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  // Display/show transparencia document in client view in different tab
  const displayDocumentoClient = async (id) => {
    const response = await axios.get(`/api/transparencias/${id}/display`, {
      method: "GET",
      responseType: "blob",
    });

    const file = new Blob([response.data], {
      type: "application/pdf",
    });

    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };
  // Add documento
  const addDocumento = async (newDoc) => {
    try {
      setIsLoading(true);
      const response = await axios.post("/api/transparencias", newDoc, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      });

      const data = await response.data;

      setDocumentos(...documentos, data);
      setIsLoading(false);
      toast.success(
        `Se ha agregado un nuevo documento de transparencia a la ASADA. Nombre: ${data.documento.dname}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } catch (error) {
      console.log(error);
      toast.error(
        `Ha ocurrido un error al agregar el documento. Error: ${error}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      throw new Error(error);
    }
  };

  // Create transparencia document
  const createTransparencia = async (newTransparencia, asadaId) => {
    try {
      setIsLoading(true);
      let response;
      if (asadaId) {
        response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias?aid=${asadaId}`,
          newTransparencia,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias?aid=${process.env.REACT_APP_ASADA_ID}`,
          newTransparencia,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      const data = await response.data;
      const transparencia = data.transparencia;
      setTransparencias(...transparencias, transparencia);
      setIsLoading(false);
      toast.success(
        `Se ha agregado un nuevo documento de transparencia a la ASADA. Nombre: ${transparencia.nombre}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      if (asadaId) await getAllTransparenciasPrivate(asadaId);
    } catch (error) {
      console.log(error);
      toast.error(
        `Ha ocurrido un error al agregar el documento de transparencia. Error: ${error}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setIsLoading(false);
      throw new Error(error);
    }
  };

  // Update transparencia admin
  const updateTransparenciaAdmin = async (
    newTransparencia,
    transparenciaId,
    restore
  ) => {
    try {
      setIsLoading(true);

      const response = await axios.patch(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/v1/transparencias/${transparenciaId}${
          restore ? "?restore=true" : ""
        }`,
        newTransparencia,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = await response.data;
      const transparencia = data.transparencia;

      await getSingleTransparencia(transparenciaId);
      setIsLoading(false);
      toast.info(
        `Documento de transparencia actualizado: "${transparencia.nombre}"`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } catch (error) {
      console.log(error);
      toast.error(
        `Ha ocurrido un error al editar el documento de transparencia. Error: ${error}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setIsLoading(false);
      throw new Error(
        `Error al actualizar documento de transparencia: ${newTransparencia}`
      );
    }
  };

  // Update documento
  const updateTransparencia = async (newTransparencia, transparenciaId) => {
    try {
      setIsLoading(true);
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias/${transparenciaId}`,
        newTransparencia,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = await response.data;
      const transparencia = await data.transparencia;

      // await getDocumentos();
      await getSingleTransparencia(transparenciaId);
      setIsLoading(false);
      toast.info(
        `Documento de transparencia actualizado: "${transparencia.nombre}"`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      throw new Error(
        `Error al actualizar documento: ${newTransparencia}. ${error}`
      );
    }
  };

  // Delete form
  const deleteTransparencia = async (transparenciaId, force) => {
    try {
      setIsLoading(true);
      await axios.delete(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/v1/transparencias/${transparenciaId}${
          force ? "?force=true" : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      await getAllTransparencias(process.env.REACT_APP_ASADA_ID);

      setIsLoading(false);
      toast.info(`Se ha borrado el registro del documento de transparencia`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate(-1);
    } catch (error) {
      console.log(error);
      throw new Error(
        `Error al borrar documento de transparencia: ${documento.nombre}`
      );
    }
  };

  return (
    <TransparenciaContext.Provider
      value={{
        documento,
        documentos,
        isLoading,
        setIsLoading,
        setDocumento,
        addDocumento,
        getDocumento,
        getDocumentos,
        updateTransparencia,
        downloadTransparenciaDocument,
        downloadTransparenciaDocumentClient,
        displayDocumento,
        displayDocumentoClient,
        deleteTransparencia,
        getTransparencias,
        transparencias,
        transparencia,
        setTransparencia,
        setTransparencias,
        getAllTransparencias,
        createTransparencia,
        getSingleTransparencia,
        getAllTransparenciasPrivate,
        updateTransparenciaAdmin,
      }}
    >
      {children}
    </TransparenciaContext.Provider>
  );
};

export default TransparenciaContext;
